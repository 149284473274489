import { Navbar } from "react-bootstrap"
import React, { Component } from "react"
import logo from "../../static/images/tbc-logo.png"
import { Link } from "gatsby"
import bunty from "../../static/images/bunty.png"

class Header extends Component {
  state = {
    mobileOpen: false,
    openMenus: [],
  }

  toggleMenu = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    })
  }

  burgerClass() {
    if (this.state.mobileOpen) {
      return "hamburger animate active"
    } else {
      return "hamburger animate collapsed"
    }
  }

  menuClass() {
    if (this.state.mobileOpen) {
      return "mobile-menu open"
    } else {
      return "mobile-menu closed"
    }
  }

  render() {
    return (
      <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
        <div className={"container h-100"}>
          <Link to="/" className={"navbar-brand"}>
            <img src={logo} alt="The Bandra Cakery" className="navbar-logo" />
          </Link>
          <div className={"navbar-brand d-lg-block d-none"}>
            <img src={bunty} alt="The Bandra Cakery" style="height: 30px;" />
          </div>
        </div>
      </Navbar>
    )
  }
}

const HeaderComponent = () => <Header />

export default HeaderComponent
