/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp"

import Header from "./header"
import footerLogo from "../../static/images/tbc-navbar.png"
import footerMobileLogo from "../../static/images/cakery-round.svg"
import "../../static/styles/main.scss"
import { library } from "@fortawesome/fontawesome-svg-core"

library.add(faTwitter)
library.add(faFacebookF)
library.add(faInstagram)
library.add(faAngleRight)
library.add(faWhatsapp)
library.add(faMap, faEnvelope, faPhone)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `
        .bottom-bar svg {
            width: 0.875em;
        }

        .think-cake {
          width: 0.875em;
        }

        .fa-2x {
          font-size: 2em;
        }
      `,
          }}
        />
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <div className={"container"}>
            <img src={footerLogo} alt={"The Bandra Cakery"} />
            <p className={"copyright"}>
              © 2019 The Bandra Cakery. All rights reserved.
            </p>
            <div style="display: none;" className={"social-icons"}>
              <a
                href="https://www.facebook.com/delcakes.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </a>
              <a
                href="https://www.instagram.com/thebandracakery.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </div>
            <div classname={"legal"} style={"display: none; font-size: 12px;"}>
              <a href="/terms">Terms and Conditions</a>
              &nbsp;|&nbsp;
              <a href="/privacy">Privacy Policy</a>
            </div>
          </div>
        </footer>
        <div className={"fixed-bottom d-flex d-md-none"}>
          <a
            href="/"
            rel="noreferrer noopener"
            className={"bottom-bar whatsapp"}
          >
            <img src={footerMobileLogo} className={"d-logo"} alt="Home" />
          </a>
          <a
            className={"bottom-bar instagram"}
            href={"https://www.instagram.com/thebandracakery.in"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "instagram"]}
              size="2x"
              className={"mr-2"}
            />
          </a>
          <a
            href="tel:9867181400"
            target="_blank"
            rel="noreferrer noopener"
            className={"bottom-bar whatsapp"}
          >
            <FontAwesomeIcon
              icon={["fas", "phone"]}
              size="2x"
              transform="rotate-90"
            />
          </a>
          <a
            href="https://wa.me/919867181400"
            target="_blank"
            rel="noreferrer noopener"
            className={"bottom-bar whatsapp"}
          >
            <FontAwesomeIcon icon={["fab", "whatsapp"]} size="2x" />
          </a>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
